/* eslint-disable react/no-multi-comp */
import { HTMLAttributes } from 'react';

export const SuccessIcon = ({ title, ...props }: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px" {...props}>
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
  </svg>
);

export const ErrorIcon = ({ title, ...props }: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px" {...props}>
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z" />
    <circle cx="12" cy="16" r="1" />
    <path d="M11 7h2v7h-2z" />
  </svg>
);

export const WarningIcon = ({ title, ...props }: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px" {...props}>
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
  </svg>
);

export const InfoIcon = ({ title, ...props }: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px" {...props}>
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);
